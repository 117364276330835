import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Form, Button } from "react-bootstrap"

const Contact = () => (
  
  <Layout pageInfo={{ pageName: "Contacto" }}>
    <SEO title="Contacto" /><br/><br/><br/>
    <h1>Contáctanos</h1>
    <Row>
      
      <Col sm={4}>
        <p><a href="mailto:isoldamcm@hotmail.com">isoldamcm@hotmail.com</a></p>

        <p>Av. Eugenio Garza Sada 2501 Sur,<br/> Tecnológico,<br/> 64849 <br/>Monterrey, N.L.</p>
        </Col>
        <Col sm={6}>
        <iframe allowfullscreen="" title="mapgoogle" frameborder="0" height="300" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14386.379874619262!2d-100.28954!3d25.6515649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xab5b4cc298e6fe08!2sTecnol%C3%B3gico%20de%20Monterrey!5e0!3m2!1ses-419!2smx!4v1576458557947!5m2!1ses-419!2smx" style={{border: 0}} width="400"></iframe>
      
        </Col>
    </Row>
  </Layout>
)

export default Contact
